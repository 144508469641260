import executeRequest, { RequestOutput } from 'app/api'
import { Customer } from 'app/entities/types'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable, APIDateFilterable } from 'app/api/types'

enum APIEndpoint {
  GetAllInvoices = 'api/invoices',
  GetInvoiceById = 'api/invoices/getById',
  CreateInvoice = 'api/invoices/create',
  UpdateInvoice = 'api/invoices/update',
  DeleteInvoice = 'api/invoices/delete',
}



export interface APIInvoice {
  id: number
  invoiceNumber?: string | null
  externalUrl?: string | null
  xeroInvoiceId?: string | null
  order?: {
    id: number
    orderNumber?: string | null,
    totalAmount?: number | null,
    currency?: {
      id: number
      name: string
      code: string
      htmlSymbol: string
    } | null
    user?: {
      id: number
      firstname: string
      lastname: string
      username: string
      email: string
      avatar: string
    } | null
  }
}


export interface GetAllInvoicesRequestParams extends APIPaginatable, APISortable, APISearchable, APIDateFilterable {
  orderId?: number
}
export interface GetAllInvoicesResponse extends APIPaginatableResponse {
  invoices: APIInvoice[]
}
enum GetAllInvoicesResponseError {
}
export async function getAllInvoices(params: GetAllInvoicesRequestParams, output?: RequestOutput<GetAllInvoicesResponse, GetAllInvoicesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllInvoices,
    params: params,
    output: output,
  })
}



export type GetInvoiceRequestParams = {
  id: number
  languageId: number
}
export type GetInvoiceResponse = {
  invoice: APIInvoice
}
enum GetInvoiceResponseError {
}
export async function getInvoice(params: GetInvoiceRequestParams, output?: RequestOutput<GetInvoiceResponse, GetInvoiceResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetInvoiceById,
    params: params,
    output: output,
  })
}




export type CreateInvoiceRequestParams = {
  orderId: number
}
export type CreateInvoiceResponse = {
}
enum CreateInvoiceResponseError {
}
export async function createInvoice(params: CreateInvoiceRequestParams, output?: RequestOutput<CreateInvoiceResponse, CreateInvoiceResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateInvoice,
    params: params,
    output: output,
  })
}


export type UpdateInvoiceRequestParams = APIInvoice
export type UpdateInvoiceResponse = {
}
enum UpdateInvoiceResponseError {
}
export async function updateInvoice(params: UpdateInvoiceRequestParams, output?: RequestOutput<UpdateInvoiceResponse, UpdateInvoiceResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateInvoice,
    params: params,
    output: output,
  })
}