import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllExchangeBookStatuses = 'api/bookStatuses',
}



export type APIExchangeBookStatus = {
  id: number
  name?: string
  keyword?: string
}



export type GetAllExchangeBookStatusesResponse = {
  bookStatuses: APIExchangeBookStatus[]
}

enum GetAllExchangeBookStatusesResponseError {
}

export async function getAllExchangeBookStatuses(output?: RequestOutput<GetAllExchangeBookStatusesResponse, GetAllExchangeBookStatusesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllExchangeBookStatuses,
    output: output,
  })
}
