import { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import { RouteConfig } from 'react-router-config'
import AuthLayout from 'views/layouts/Auth'
import ErrorLayout from 'views/layouts/Error'
import SidebarLayout from 'views/layouts/Sidebar'
import InitialView from 'views/screens/core/general/Dashboard'

const routes: RouteConfig[] = [

	//Root
	{ path: '/', exact: true, component: () => <Redirect to='/dashboard' /> },

	//Auth
	{
		path: '/auth', component: AuthLayout as any, routes: [
			{ path: '/auth/login', exact: true, component: lazy(() => import('views/screens/core/auth/Login')) },
			{ path: '/auth/logout', exact: true, component: lazy(() => import('views/screens/core/auth/Logout')) },
			{ path: '/auth/reset-password', exact: true, component: lazy(() => import('views/screens/core/auth/ResetPassword')) },
			{ component: () => <Redirect to='/error/404' /> }
		]
	},

	//Errors
	{
		path: '/error', component: ErrorLayout as any, routes: [
			{ path: '/error/401', exact: true, component: lazy(() => import('views/screens/core/errors/Error401')) },
			{ path: '/error/403', exact: true, component: lazy(() => import('views/screens/core/errors/Error403')) },
			{ path: '/error/404', exact: true, component: lazy(() => import('views/screens/core/errors/Error404')) },
			{ path: '/error/500', exact: true, component: lazy(() => import('views/screens/core/errors/Error500')) },
			{ component: () => <Redirect to='/error/404' /> }
		]
	},

	//Main views
	{
		route: '*', component: SidebarLayout as any, routes: [
			{ path: '/dashboard', exact: true, component: InitialView, },

			{ path: '/website/pages', exact: true, component: lazy(() => import('views/screens/core/website/Pages')) },
			{ path: '/website/pages/page/:id/:tab?', exact: true, component: lazy(() => import('views/screens/core/website/PageDetails')) },
			{ path: '/website/dictionary', exact: true, component: lazy(() => import('views/screens/core/website/Dictionary')) },
			{ path: '/website/dictionary/entry/:id', exact: true, component: lazy(() => import('views/screens/core/website/DictionaryDetails')) },
			{ path: '/website/blog/:tab?', exact: true, component: lazy(() => import('views/screens/core/website/Articles')), },
			{ path: '/website/blog/article/:id/:tab?', exact: true, component: lazy(() => import('views/screens/core/website/ArticleDetails')) },
			{ path: '/website/blog/category/:id/:tab?', exact: true, component: lazy(() => import('views/screens/core/website/ArticlesCategoryDetails')) },

			{ path: '/shop/items/:tab?', exact: true, component: lazy(() => import('views/screens/core/shop/Products')), },
			{ path: '/shop/items/product/:id/:tab?', exact: true, component: lazy(() => import('views/screens/core/shop/ProductDetails')) },
			{ path: '/shop/items/category/:id/:tab?', exact: true, component: lazy(() => import('views/screens/core/shop/ProductsCategoryDetails')) },
			{ path: '/shop/wishlists', exact: true, component: lazy(() => import('views/screens/core/shop/Wishlists')), },
			{ path: '/shop/carts', exact: true, component: lazy(() => import('views/screens/core/shop/Carts')), },
			{ path: '/shop/carts/cart/:id/:tab?', exact: true, component: lazy(() => import('views/screens/core/shop/CartDetails')) },
			{ path: '/shop/orders', exact: true, component: lazy(() => import('views/screens/core/shop/Orders')), },
			{ path: '/shop/orders/order/:id/:tab?', exact: true, component: lazy(() => import('views/screens/core/shop/OrderDetails')) },
			{ path: '/shop/invoices', exact: true, component: lazy(() => import('views/screens/core/shop/Invoices')), },
			{ path: '/shop/invoices/invoice/:id/:tab?', exact: true, component: lazy(() => import('views/screens/core/shop/InvoiceDetails')) },
			{ path: '/shop/customers', exact: true, component: lazy(() => import('views/screens/core/shop/Customers')), },

			{ path: '/admin/users', exact: true, component: lazy(() => import('views/screens/core/admin/Users')) },
			{ path: '/admin/users/user/:id/:tab?', exact: true, component: lazy(() => import('views/screens/core/admin/UsersDetails')) },
			{ path: '/admin/automation', exact: true, component: lazy(() => import('views/screens/core/admin/Automation')), },
			{ path: '/admin/settings', exact: true, component: lazy(() => import('views/screens/core/admin/Settings')), },

			{ path: '/dev/overview/', exact: true, component: lazy(() => import('views/screens/dev/Overview')) },
			{ path: '/dev/pages/:id/manage', exact: true, component: lazy(() => import('views/screens/dev/DevPageSectionsManager')) },
			{ path: '/dev/media-library/folders', exact: true, component: lazy(() => import('views/screens/dev/DevFolders')) },
			{ path: '/dev/media-library/folders/:id', exact: true, component: lazy(() => import('views/screens/dev/DevFolderDetails')) },
			{ path: '/dev/types/page', exact: true, component: lazy(() => import('views/screens/dev/DevPageTypes')) },
			{ path: '/dev/types/page/:id', exact: true, component: lazy(() => import('views/screens/dev/DevPageTypesDetails')) },
			{ path: '/dev/types/field-group', exact: true, component: lazy(() => import('views/screens/dev/DevFieldGroupTypes')) },
			{ path: '/dev/types/field-group/:id', exact: true, component: lazy(() => import('views/screens/dev/DevFieldGroupTypesDetails')) },
			{ path: '/dev/types/field', exact: true, component: lazy(() => import('views/screens/dev/DevFieldTypes')) },
			{ path: '/dev/types/field/:id', exact: true, component: lazy(() => import('views/screens/dev/DevFieldTypesDetails')) },
			{ path: '/dev/tags', exact: true, component: lazy(() => import('views/screens/dev/DevTags')) },
			{ path: '/dev/tags/:id', exact: true, component: lazy(() => import('views/screens/dev/DevTagsDetails')) },
			{ path: '/dev/users/roles', exact: true, component: lazy(() => import('views/screens/dev/DevRoles')) },
			{ path: '/dev/users/roles/:id', exact: true, component: lazy(() => import('views/screens/dev/DevRolesDetails')) },
			{ path: '/dev/users/permissions', exact: true, component: lazy(() => import('views/screens/dev/DevPermissions')) },
			{ path: '/dev/users/permissions/:id', exact: true, component: lazy(() => import('views/screens/dev/DevPermissionsDetails')) },
			// { path: '/dev/pages/regions', exact: true, component: lazy(() => import('views/screens/dev/DevRegions')) },
			// { path: '/dev/pages/regions/:id/:tab?', exact: true, component: lazy(() => import('views/screens/dev/DevRegionDetails')) },
			// { path: '/dev/pages/provinces', exact: true, component: lazy(() => import('views/screens/dev/DevProvinces')) },
			// { path: '/dev/pages/provinces/:id/:tab?', exact: true, component: lazy(() => import('views/screens/dev/DevProvinceDetails')) },
			// { path: '/dev/pages/cities', exact: true, component: lazy(() => import('views/screens/dev/DevCities')) },
			// { path: '/dev/pages/cities/:id/:tab?', exact: true, component: lazy(() => import('views/screens/dev/DevCityDetails')) },

			{ component: () => <Redirect to='/error/404' /> }
		]
	},
]

export default routes