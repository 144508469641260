import { useState, useEffect, ReactNode } from 'react'
import clone from 'lodash/cloneDeep';
import { Card } from '@material-ui/core'
import { CardField, CardHeader, CardContent, FieldsListErrors, PageEntityDetailsRefreshableProps } from 'components'
import { processFieldsErrors, prepareForSave, shouldSave, useLocalization } from 'components/methods'
import { Page, PageType, PageTranslation, Language } from 'app/entities/types'
import { toast } from 'app/utils'
import { default as slugify } from 'slug'
import {
  updatePage, UpdatePageRequestParams,
  getPageTranslation, GetPageTranslationRequestParams, GetPageTranslationResponse,
  updatePageTranslation, UpdatePageTranslationRequestParams,
} from 'app/api'
import { userCan } from 'app/entities/methods'
import { useSelector } from 'react-redux'
import { RootState } from 'app/session/store'
import { UserPermissionValue } from 'app/values'


enum FieldName {
  Name = 'translationName',
  Keyword = 'pageKeyword',
  Slug = 'translationSlug',
  ParentId = 'pageParentId',
}

type DataProps = {
  pageId: number
  pageType: PageType | null
  translationId: number
  pageParentId: number | null
  translationName: string | null
  pageKeyword: string | null
  translationSlug: string | null
  translationLanguage?: Language | null
}

interface PageDataProps extends PageEntityDetailsRefreshableProps {
  object: Page,
  language: Language,
  pageId: number
  parents?: Page[] | null
  action?: ReactNode
}
const PageData = ({ ...props }: PageDataProps) => {
  const session = useSelector((state: RootState) => state.session)
  const { t } = useLocalization()

  const [isLoading, setIsLoading] = useState(true)

  const [translation, setTranslation] = useState<PageTranslation | null>()
  const [options, setOptions] = useState<{ key: string, value: string | number, name: string }[]>([])



  //OBJECT

  const [data, setData] = useState<DataProps>({
    pageId: props.pageId,
    pageType: props.object.pageType ?? null,
    translationId: 0,
    [FieldName.ParentId]: props.object.parentId ?? null,
    [FieldName.Name]: '',
    [FieldName.Keyword]: props.object.keyword,
    [FieldName.Slug]: '',
    translationLanguage: props.language
  })
  const [currentData, setCurrentData] = useState(data)
  const [isUpdatingCurrentData, setIsUpdatingCurrentData] = useState(false)

  function updateData(data: DataProps) {
    setIsUpdatingCurrentData(true)
    setData(data)
    setCurrentData(data)
  }

  useEffect(() => {
    setIsUpdatingCurrentData(false)
  }, [currentData])

  useEffect(() => {
    let initialized = props.object != null
      && translation != null

    //Check if parents are enabled.
    if (props.parents !== undefined) {
      initialized = props.parents != null
    }

    setIsLoading(!initialized)
  }, [props.object, translation, props.parents])



  //ERROR HANDLING

  const errorsConditions: FieldsListErrors = {
    [FieldName.Name]: [
      { error: data.translationName === '', errorMessage: t('tab.page-data.data.name.error.empty') },
    ],
    [FieldName.Keyword]: [
      { error: data.pageKeyword === '', errorMessage: t('tab.page-data.data.keyword.error.empty') },
      { error: data.pageKeyword == null, errorMessage: t('tab.page-data.data.keyword.error.empty') },
    ],
    [FieldName.Slug]: [
      { error: data.translationSlug === '', errorMessage: t('tab.page-data.data.slug.error.empty') },
      { error: data.translationSlug == null, errorMessage: t('tab.page-data.data.slug.error.empty') },
    ],
  }

  const [errors, setErrors] = useState<FieldsListErrors>({})

  useEffect(() => {
    setErrors(processFieldsErrors(FieldName, errorsConditions))
  }, [data])



  //FIELD UPDATES

  function updateFieldValue(name: string, value: any) {
    const temp = clone(data)

    switch (name) {
      case FieldName.Name: temp.translationName = value; break
      case FieldName.Keyword: temp.pageKeyword = slugify(value); break
      case FieldName.Slug: temp.translationSlug = slugify(value); break
      case FieldName.ParentId: temp.pageParentId = value ? parseInt(value) : null; break
      default: break
    }

    setData(temp)
  }

  function confirmFieldValue(name: string, value: any) {
    save(name as FieldName)
  }



  //SAVE

  function save(name?: FieldName) {
    if (shouldSave({ isUpdatingData: isUpdatingCurrentData, fieldName: name, fieldParameters: errorsConditions, data: data, currentData: currentData }) === false) return
    prepareForSave({ fieldName: name, data: data, currentData: currentData, updateDataCallback: setCurrentData })

    savePageObject()

    function savePageObject() {
      const encode = (): UpdatePageRequestParams => {
        return {
          id: props.pageId,
          keyword: data.pageKeyword ?? '',
          parentId: data.pageParentId,
          tags: []
        }
      }

      updatePage(encode(), {
        response(data) {
          savePageTranslation()
          // toast.success(t('alert.page-data.data.update'))
          // if (props.refreshObject != null) props.refreshObject()
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    function savePageTranslation() {
      const encode = (): UpdatePageTranslationRequestParams => {
        return {
          id: data.translationId,
          name: data.translationName,
          slug: data.translationSlug,
        }
      }

      updatePageTranslation(encode(), {
        response(data) {
          toast.success(t('alert.page-data.data.update'))
          if (props.refreshObject != null) props.refreshObject()
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }
  }



  //PARENTS

  useEffect(() => {
    if (props.parents == null) return
    let opt: any = []
    props.parents.forEach((p, i) => {
      opt.push({ key: i, value: p.id, name: p.translation[0].name })
    })
    setOptions(opt)
  }, [props.parents])



  //TRANSLATION

  useEffect(() => {
    const fetchPageTranslation = () => {
      if (props.object == null) return
      if (props.language == null) return

      const encode = (): GetPageTranslationRequestParams => {
        return {
          pageId: props.pageId,
          languageId: props.language.id,
        }
      }
      const decode = (data: GetPageTranslationResponse): PageTranslation => {
        return {
          id: data.pageTranslation.id,
          name: data.pageTranslation.name,
          slug: data.pageTranslation.slug,
          language: props.language
        }
      }

      setIsLoading(true)
      const params = encode()
      getPageTranslation(params, {
        response(data) {
          const translation = decode(data)
          setTranslation(translation)
          setIsLoading(false)
        },
        error(error, message) {
          toast.error(message)
          setIsLoading(false)
        }
      })
    }

    fetchPageTranslation()
  }, [props.language])

  useEffect(() => {
    if (translation == null) return

    const temp = clone(data)
    temp.translationId = translation.id ?? 0
    temp.translationName = translation.name ?? ''
    temp.translationSlug = translation.slug ?? ''
    temp.translationLanguage = translation.language

    updateData(temp)
  }, [translation])



  //RENDER

  return (
    <>
      <Card>
        <CardHeader title={t('tab.page-data.data.title')} />
        <CardContent isLoading={isLoading} >

          <CardField type={'text'} name={FieldName.Name}
            label={t('tab.page-data.data.name.label')} placeholder={t('tab.page-data.data.name.placeholder')}
            value={data.translationName} errors={errors}
            onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
          />

          {userCan(session.user, UserPermissionValue.DeveloperFeatures) === true &&
            <CardField type={'text'} name={FieldName.Keyword}
              label={t('tab.page-data.data.keyword.label')} placeholder={t('tab.page-data.data.keyword.placeholder')} helpDescription={t('tab.page-data.data.keyword.helpDescription')}
              value={data.pageKeyword} errors={errors}
              onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
            />
          }

          <CardField type={'text'} name={FieldName.Slug}
            label={t('tab.page-data.data.slug.label')} placeholder={t('tab.page-data.data.slug.placeholder')} helpDescription={t('tab.page-data.data.slug.helpDescription')}
            value={data.translationSlug} errors={errors}
            onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
          />

          {props.parents != null &&
            <CardField type={'select'} name={FieldName.ParentId}
              label={t('tab.page-data.data.parent.label')} placeholder={t('tab.page-data.data.parent.placeholder')}
              value={data.pageParentId ?? ''} errors={errors}
              options={options} enableClear={true}
              onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
            />
          }

          {props.action != null && props.action}

        </CardContent>
      </Card>
    </>
  )
}

export default PageData