import { APIExchangeBook } from 'app/api'
import { ExchangeBook, Product } from 'app/entities/types'

export function constructExchangeBook(api: APIExchangeBook): ExchangeBook {
  return {
    id: api.id,
    bookNumber: api.bookNumber,
    product: api.product,
    user: api.user,
    price: api.price,
    currency: api.currency,
    quantity: api.quantity,
    bookType: api.bookType,
    bookStatus: api.bookStatus,
    commissionFee: api.commissionFee,
    date: api.creationDate
  }
}
