import { APIExchangeBookMatch } from 'app/api'
import { ExchangeBookMatch, Product } from 'app/entities/types'

export function constructExchangeBookMatch(api: APIExchangeBookMatch): ExchangeBookMatch {
  return {
    id: api.id,
    bookMatchNumber: api.exchangeBookMatchNumber,
    buyer: api.exchangeBookBuyer,
    seller: api.exchangeBookSeller,
    matchStatus: api.matchStatus,
    date: api.creationDate
  }
}
