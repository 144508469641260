import { APIConfig } from 'app/api'
import { Config } from 'app/entities/types'

export function constructConfig(api: APIConfig): Config {
  return {
    id: api.id,
    keyword: api.keyword,
    value: api.value,
  }
}
