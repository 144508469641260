import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'
import { APIUser, APIProduct, APICurrency, APIExchangeBookType, APIExchangeBookStatus } from 'app/api'

enum APIEndpoint {
  GetAllExchangeBooks = 'api/exchangeBooks',
  GetExchangeBookById = 'api/exchangeBooks/getById',
  CreateExchangeBook = 'api/exchangeBooks/create',
  DeleteExchangeBook = 'api/exchangeBooks/delete',
}



export type APIExchangeBook = {
  id: number
  bookNumber?: string
  product?: APIProduct
  user?: APIUser
  price?: number
  currency?: APICurrency
  quantity?: number
  bookType?: APIExchangeBookType
  bookStatus?: APIExchangeBookStatus
  commissionFee?: number
  creationDate?: Date
}



//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export interface GetExchangeBooksRequestParams extends APIPaginatable, APISortable, APISearchable {
  userId?: number,
  bookTypeId?: number,
  productId?: number | null
}
export interface GetExchangeBooksResponse extends APIPaginatableResponse {
  exchangeBooks: APIExchangeBook[]
}
enum GetExchangeBooksResponseError {
}
export async function getExchangeBooks(params: GetExchangeBooksRequestParams, output?: RequestOutput<GetExchangeBooksResponse, GetExchangeBooksResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllExchangeBooks,
    params: params,
    output: output,
  })
}




export type GetExchangeBookRequestParams = {
  id: number
}
export type GetExchangeBookResponse = {
  exchangeBooks: APIExchangeBook
}
enum GetExchangeBookResponseError {
}
export async function getExchangeBook(params: GetExchangeBookRequestParams, output?: RequestOutput<GetExchangeBookResponse, GetExchangeBookResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetExchangeBookById,
    params: params,
    output: output,
  })
}



export type CreateExchangeBookRequestParams = {
  userId: number,
  bookTypeId: number,
  productId: number,
  currencyId: number,
  price: number
  quantity: number
}
type CreateExchangeBookResponse = {
}
enum CreateExchangeBookReponseError {
}
export async function createExchangeBook(params: CreateExchangeBookRequestParams, output?: RequestOutput<CreateExchangeBookResponse, CreateExchangeBookReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateExchangeBook,
    params: params,
    output: output,
  })
}




export type DeleteExchangeBookRequestParams = {
  id: number
}
type DeleteExchangeBookResponse = {
}
enum DeleteExchangeBookReponseError {
}
export async function deleteExchangeBook(params: DeleteExchangeBookRequestParams, output?: RequestOutput<DeleteExchangeBookResponse, DeleteExchangeBookReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteExchangeBook,
    params: params,
    output: output,
  })
}