import { APICron } from 'app/api'
import { Cron } from 'app/entities/types'

export function constructCron(api: APICron): Cron {
  return {
    code: api.code,
    name: api.name,
    description: api.description,
    frequency: api.frequency,
  }
}
