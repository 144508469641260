import { APIProduct } from 'app/api'
import { Product } from 'app/entities/types'

export function constructProduct(api: APIProduct): Product {
	return {
		id: api.id,
		keyword: api.keyword,
		published: api.published,
		sku: api.sku,
		featured: api.featured,
		featuredOrder: api.featuredOrder,
		pageId: api.pageId,
		parentId: api.parentId,
		tokenized: api.tokenized,
		onEnquiry: api.onEnquiry,
		year: api.year,
		translation: api.translation,
		taxonomies: api.taxonomies,
		prices: api.prices,
		dimensions: api.dimensions,
		stock: api.stock,
	}
}
