import { Badge, Card, IconButton, makeStyles, Menu, MenuItem, TableSortLabel, Typography, Checkbox, ListItemIcon } from '@material-ui/core'
import SortingArrowIcon from '@material-ui/icons/ExpandMore'
import FilterListIcon from '@material-ui/icons/FilterList'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import SearchIcon from '@material-ui/icons/Search'
import { APIPaginatable, APISearchable, APISortable, APIDateFilterable } from 'app/api/types'
import { config } from 'app/config'
import { updatePersistedListParameters } from 'app/session/actions'
import { RootState } from 'app/session/store'
import { useRouter, SEOScoreDevice, SEOScoreType } from 'app/utils'
import clsx from 'clsx'
import moment from 'moment'
import _ from 'lodash'
import { CardContent, CardHeader, Tooltip, Spinner, CardField, EmojiResults } from 'components'
import { useLocalization, randomEmoji } from 'components/methods'
import React, { Fragment, ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontSize, FontWeight, Color } from 'theme/style'
import { CardListingFilterDialog, CardListingFooter } from './'
import PublicIcon from '@material-ui/icons/Visibility'
import LockIcon from '@material-ui/icons/VisibilityOff'
import DownloadIcon from '@material-ui/icons/GetApp'
import InfoIcon from '@material-ui/icons/Info'
import ClearIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'
import { lighten } from '@material-ui/core/styles'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import DesktopMacIcon from '@material-ui/icons/DesktopMac'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import RefreshIcon from '@material-ui/icons/Refresh'

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardFullSize: {
    height: `calc(100vh - 144px)`,
    [theme.breakpoints.down('sm')]: {
      height: `calc(100vh - 105px)`,
    },
  },

  actionSelectionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  actionSelectionText: {
    textAlign: 'right'
  },

  dateFieldsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  dateField: {
    width: '100%',
    height: '3.6rem',
    backgroundColor: Color.Background,
    borderRadius: '1.8rem',
    minWidth: '100px',
    marginRight: theme.spacing(1)
  },

  searchBar: {
    minWidth: '300px',
  },

  filterButton: {
    marginLeft: theme.spacing(1),
    height: '3.6rem',
    width: '3.6rem',
    backgroundColor: Color.Background,
  },
  filterButtonIcon: {
    width: '20px',
    height: '20px',
    color: Color.TextSecondary
  },
  filtersBadge: {
    backgroundColor: Color.Notification,
    color: Color.White,
    fontSize: FontSize.Body2
  },

  tableContainer: {
    height: '100%',
    position: 'relative',
  },
  table: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'scroll',
    '&:after': {
      width: '100%',
      height: '80px',
      display: 'block',
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      background: 'linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
      zIndex: 99,
      pointerEvents: 'none'
    }
  },
  sortingIcon: {
    '& >svg': {
      position: 'absolute',
      right: '-22px'
    },
  },

  tableHead: {
    alignItems: 'center',
    display: 'flex',
    height: '50px',
    padding: theme.spacing(1, 0),
    width: '100%',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },

  tableBody: {
    width: '100%',
    paddingBottom: '60px',
    '&:before': {
      width: '14px',
      height: '100%',
      display: 'block',
      content: '""',
      position: 'absolute',
      left: '0px',
      top: 0,
      background: 'linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
      zIndex: 99,
    },
    '&:after': {
      width: '14px',
      height: '100%',
      display: 'block',
      content: '""',
      position: 'absolute',
      right: '0px',
      top: 0,
      background: 'linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
      zIndex: 99,
    }
  },

  tableRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: '40px',
    margin: 0,
    padding: theme.spacing(1),
    width: '100%',
    flexFlow: 'row nowrap',
  },
  tableRowCompact: {
    height: '32px !important'
  },
  tableHeadRow: {
  },
  tableBodyRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: Color.Background
    }
  },
  tableBodyRowContent: {
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: 0,
  },

  tableCell: {
    ...theme.typography.body1,
    position: 'relative',
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    flex: '0 0 200px',
    minWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
    },
  },
  tableHeadCell: {
    height: '50px',
    padding: theme.spacing(0, 1) + ' !important',
    backgroundColor: Color.White,
    borderBottom: '2px solid transparent',
    borderBottomColor: Color.Background,
  },
  tableBodyCell: {
    height: '40px',
    padding: theme.spacing(0, 1) + ' !important',
    borderBottom: '1px solid transparent',
    borderBottomColor: Color.Background,
    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      position: 'relative',
    }
  },

  highlightText: {
    backgroundColor: Color.TextHighlight,
  },

  colCheckbox: {
    flex: '0 0 48px',
    maxWidth: '48px',
    minWidth: '48px',
    textAlign: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '0 !important',
    '& svg': {
      width: '1.4em',
      height: '1.4em',
    }
  },

  colId: {
    flex: '0 0 55px',
    maxWidth: '55px',
    minWidth: '55px',
    textAlign: 'left',
    justifyContent: 'flex-start'
  },
  colId_body: {
    flex: '0 0 55px',
    maxWidth: '55px',
    minWidth: '55px',
    fontWeight: FontWeight.Medium,
    color: lighten(Color.TextSecondary, 0.4),
    textAlign: 'left',
    justifyContent: 'flex-start'
  },

  colPageStatus: {
    flex: '0 0 60px',
    maxWidth: '60px',
    minWidth: '60px',
    textAlign: 'center',
    justifyContent: 'center'
  },
  colPageStatus_body: {
    flex: '0 0 60px',
    maxWidth: '60px',
    minWidth: '60px',
    textAlign: 'center',
    justifyContent: 'center'
  },

  colPageMeta: {
    flex: '0 0 80px',
    maxWidth: '80px',
    minWidth: '80px',
    textAlign: 'center',
    justifyContent: 'center'
  },
  colPageMeta_body: {
    flex: '0 0 80px',
    maxWidth: '80px',
    minWidth: '80px',
    textAlign: 'center',
    justifyContent: 'center'
  },

  colBold: {
    fontWeight: FontWeight.Medium,
  },
  colFlexible: {
    flex: 1
  },
  colAlignedLeft: {
    justifyContent: 'flex-start',
    '& div': {
      marginLeft: 0
    }
  },
  colAlignedCenter: {
    justifyContent: 'center',
    '& div': {
      marginLeft: 0
    }
  },
  colAlignedRight: {
    justifyContent: 'flex-end',
    '& div': {
      marginRight: 0
    }
  },

  statusIcon: {
    height: '2rem',
    width: '2rem',
    position: 'relative',
    top: '2px',
  },
  statusIconPublished: {
    color: '#24B6FF',
  },
  statusIconPrivate: {
    color: '#b8b9b9',
  },

  metaStatusIcon: {
    height: '1.8rem',
    width: '1.8rem',
    position: 'relative',
    top: '2px',
  },
  metaStatusIconBad: {
    color: Color.Error
  },
  metaStatusIconMediocre: {
    color: Color.Warning
  },
  metaStatusIconGood: {
    color: Color.Success
  },

  availabilityIcon: {
    height: '2rem',
    width: '2rem',
    position: 'relative',
    top: '2px',
  },
  availabilityIconAvailable: {
    color: Color.Success,
  },
  availabilityIconUnavailable: {
    color: Color.Error,
  },

  colItemContainer: {
    display: 'flex',
    alignItems: 'center'
  },

  infoIcon: {
    color: Color.TextSecondary,
    opacity: 0.5,
    cursor: 'pointer',
    height: '1.7rem',
    width: '1.7rem',
    marginLeft: theme.spacing(0.5)
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  refreshButton: {
    position: 'relative',
    left: '-15px'
  },
  refreshButtonIcon: {

  }
}))


//TYPES

export enum TableResultsOption {
  _10 = 10,
  _25 = 25,
  _50 = 50,
  _100 = 100,
  _250 = 250,
  _500 = 500,
  _1000 = 1000,
  _2500 = 2500,
  _5000 = 5000,
  _10000 = 10000,
}

//List data.
type HeaderProps = {
  key: string
  name?: string | ReactNode
  info?: string
  show?: boolean
  sortable?: boolean
  searchable?: boolean
  className?: string
  bolder?: boolean,
  flexible?: boolean,
  alignedRight?: boolean,
}
export type ListHeaderProps = HeaderProps[]

type ListRowItem = {
  key: string,
  value: string | ReactNode
  className?: string
  clip?: boolean
}
export type ListRowProps = {
  item?: any
  action?: (item: any) => void
  url?: {
    path: string,
    external?: boolean
  }
  className?: string
  data: ListRowItem[]
}
export type ListDataSource = ListRowProps[]

//Sorting.
export type ListSorting = {
  order?: string
  direction?: ListSortingDirection
}
export type ListSortingDirection = 'asc' | 'desc'

//Selection options.
export type CardListingSelectionOption = {
  menuItem: ReactNode,
  action: (items: any[]) => void
}
export type CardListingSelectionOptions = CardListingSelectionOption[]

//Filters.
type ListFilters = { [key: string]: any }
export type CardListingFilterProps = {
  listId: string
  name: string
  className?: string
  onFilterCallback?: (key: string, value: any) => void
}

//Request.
export interface ListAPIRequestParameters extends APIPaginatable, APISortable, APISearchable, APIDateFilterable {
}
export interface ListRequestParameters extends ListAPIRequestParameters {
  filters?: ListFilters
}



//PROPS

type CardListingProps = {

  //Identifier used to store list parameters in session for persistence between screens.
  id: string

  //Use a compact layout (useful in long lists)
  compact?: boolean

  //Sets a loading layout for the component.
  isLoading?: boolean

  //Sets a full height layout for the component.
  fullSize?: boolean

  //Required attributes.
  title: string
  items: any[] | null

  //The headers of the list.
  headers: ListHeaderProps

  //The function used to build each list's row.
  //Each item in the "item" property will be passed to this function as an argument.
  dataConstructor: (item: any) => ListRowProps

  //Optional parameters used to handle pagination. Should be used when the list is handled by API requests.
  count?: number
  page?: number
  handleChangePageCallback?: (page: number) => void
  rowsPerPage?: number
  handleChangeRowsPerPageCallback?: (rows: number) => void
  resultsOptions?: number[]

  //Optional sorting parameters.
  sorting?: ListSorting
  handleChangeSortingCallback?: (sorting: ListSorting) => void

  //Enable footer.
  enableFooter?: boolean

  //Enable search field.
  enableSearch?: boolean

  //Enable list refresh.
  enableRefresh?: boolean

  //Enable dates field.
  enableDates?: boolean
  fromDate?: Date
  toDate?: Date


  //Enable row seletion.
  enableSelection?: boolean
  selectionOptions?: CardListingSelectionOptions

  //Add actions.
  actions?: ReactNode

  //Defines a list of export actions, ideally MenuItem objects
  enableExport?: boolean
  exportItems?: ReactNode[]
  exportAllCallback?: () => void
  isExporting?: boolean

  //Custom filters added to the card.
  filtersNodes?: ReactNode[]

  //Defines if the list changes should be handled by API requests instead of proceddes client-side when sorting, paginating and filtering.
  async?: boolean

  //Function that triggers the API request to fetch the items.
  fetchRequestCallback?: (requestParams: ListAPIRequestParameters, listParameters?: ListRequestParameters) => void
}



//COMPONENT

const CardListing = ({ ...props }: CardListingProps) => {

  //PARAMETERS

  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useLocalization()
  const session = useSelector((state: RootState) => state.session)
  const router = useRouter()
  const [dataValue, setDataValue] = useState<ListDataSource | null>(null)

  const [filtersDialogShowed, setFiltersDialogShowed] = useState(false)

  //Persisted parameters.
  const existingStoredParameters = session.persistedListParameters.find(i => i.listId === props.id) ?? null
  const storedParameters = existingStoredParameters ?? null

  //Async.
  const defaultAsyncValue = false
  const asyncValue = props.async ?? defaultAsyncValue

  //Page.
  const defaultPageValue = 0
  const [pageValue, setPageValue] = useState<number>(() => {
    if (storedParameters != null && storedParameters.page != null) return storedParameters.page ?? defaultPageValue
    if (props.page != null) return props.page
    return defaultPageValue
  })

  //Rows per page.
  const defaultRowsPerPageValue = TableResultsOption._50
  const [rowsPerPageValue, setRowsPerPageValue] = useState<number>(() => {
    if (storedParameters != null && storedParameters.rowsPerPage != null) return storedParameters.rowsPerPage
    if (props.rowsPerPage != null) return props.rowsPerPage
    return defaultRowsPerPageValue
  })

  //Count.
  const defaultCountValue = 0
  const [countValue, setCountValue] = useState<number>(props.count ?? defaultCountValue)

  //Sorting.
  const defaultSortingValue = {}
  const [sortingValue, setSortingValue] = useState<ListSorting>(() => {
    if (storedParameters != null && storedParameters.sorting != null) return storedParameters.sorting
    if (props.sorting != null) return props.sorting
    return defaultSortingValue
  })

  //Selection.
  const defaultEnableSelectionValue = false
  const enableSelectionValue = props.enableSelection ?? defaultEnableSelectionValue
  const [selections, setSelections] = useState<number[]>([])
  const [selectedItems, setSelectedItems] = useState<any[]>([])

  //Refresh.
  const defaultEnableRefreshValue = true
  const enableRefreshValue = props.enableRefresh ?? defaultEnableRefreshValue

  //Search.
  const defaultEnableSearchValue = true
  const enableSearchValue = props.enableSearch ?? defaultEnableSearchValue

  const defaultSearchValue: string = ''
  const [searchValue, setSearchValue] = useState<string>(() => {
    if (storedParameters != null && storedParameters.query != null) return storedParameters.query
    return defaultSearchValue
  })
  const [searchQuery, setSearchQuery] = useState<string>(() => {
    if (storedParameters != null && storedParameters.query != null) return storedParameters.query
    return defaultSearchValue
  })

  //Dates.
  const defaultEnableDatesValue = false
  const enableDatesValue = props.enableDates ?? defaultEnableDatesValue

  const defaultFromDateValue = props.fromDate ?? null
  const [fromDateValue, setFromDateValue] = useState<Date | null>(() => {
    if (storedParameters != null && storedParameters.fromDate != null) return storedParameters.fromDate
    return defaultFromDateValue
  })

  const defaultToDateValue = props.toDate ?? null
  const [toDateValue, setToDateValue] = useState<Date | null>(() => {
    if (storedParameters != null && storedParameters.toDate != null) return storedParameters.toDate
    return defaultToDateValue
  })

  //Filters.
  const defaultFiltersValue: ListFilters = {}
  const [filtersValue, setFiltersValue] = useState(() => {
    if (storedParameters != null) return storedParameters.filters
    return defaultFiltersValue
  })
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(() => {
    if (storedParameters != null) return Object.keys(storedParameters).length ?? 0
    return 0
  })



  //FETCHING

  const fetchItems = () => {
    if (asyncValue === false) return
    if (props.fetchRequestCallback == null) return

    const offset = pageValue * rowsPerPageValue
    const limit = rowsPerPageValue

    //Compose parameters used in the API request.
    let apiRequestParams: ListAPIRequestParameters = {}
    let allRequestParams: ListRequestParameters = {}

    if (offset != null) apiRequestParams.offset = offset
    if (limit != null) apiRequestParams.limit = limit
    if (searchQuery != null && searchQuery !== '') apiRequestParams.querySearch = searchQuery
    if (fromDateValue != null) apiRequestParams.fromDate = moment(fromDateValue).format(config.api.dateFormat)
    if (toDateValue != null) apiRequestParams.toDate = moment(toDateValue).format(config.api.dateFormat)
    if (sortingValue != null) {
      apiRequestParams.orderColumn = sortingValue.order
      apiRequestParams.orderType = sortingValue.direction
    }

    //Compose parameters used in the all request.
    allRequestParams = _.clone(apiRequestParams)
    if (filtersValue != null && Object.entries(filtersValue).length !== 0) allRequestParams.filters = filtersValue

    props.fetchRequestCallback(apiRequestParams, allRequestParams)
  }



  //SESSION

  function updateSession() {
    //Stores all the current list parameters in session to persist later.
    dispatch(updatePersistedListParameters({
      listId: props.id,
      page: pageValue,
      rowsPerPage: rowsPerPageValue,
      query: searchQuery,
      fromDate: fromDateValue,
      toDate: toDateValue,
      sorting: {
        order: sortingValue.order,
        direction: sortingValue.direction
      },
      filters: filtersValue
    }))
  }



  //PAGINATION

  function handleChangePage(page: number) {
    setPageValue(page)
    if (props.handleChangePageCallback != null) props.handleChangePageCallback(page)
  }

  function handleChangeRowsPerPage(rows: number) {
    setRowsPerPageValue(rows)
    if (props.handleChangeRowsPerPageCallback != null) props.handleChangeRowsPerPageCallback(rows)
  }



  //COUNT

  useEffect(() => {
    //Used only if the list is handled by API requests, since in the opposite case the count value is automatically calculated based on the entire data length.
    if (asyncValue === false) return
    if (props.count == null) return
    setCountValue(props.count)
  }, [props.count])



  //SORTING

  function handleChangeSorting(key: string) {
    //Sets/inverts the sorting order based on the current sorting status.
    let direction: ListSortingDirection = 'asc'
    if (key === sortingValue.order) direction = sortingValue.direction === 'asc' ? 'desc' : 'asc'
    const newSorting = { order: key, direction: direction }
    setSortingValue(newSorting)

    if (props.handleChangeSortingCallback != null) props.handleChangeSortingCallback(newSorting)
  }



  //SELECTION

  function handleSelection(index: number) {
    const currentSelections = _.clone(selections)

    const alreadySelected = rowIsSelected(index)
    if (alreadySelected === true) _.remove(currentSelections, function (i) { return i === index })
    else currentSelections.push(index)

    setSelections(currentSelections)
  }

  function rowIsSelected(index: number) {
    return selections.some(i => i === index)
  }

  function toggleSelectAll() {
    if (props.items == null) return

    if (props.items.length === selections.length) {
      setSelections([])
      return
    }

    const currentSelections: number[] = []
    for (let i = 0; i < props.items.length; i++) currentSelections.push(i)
    setSelections(currentSelections)
  }

  useEffect(() => {
    let currentSelectedItems = prepareDataForDisplay().filter((item, itemIndex) => {
      return selections.some(i => i === itemIndex)
    })
    currentSelectedItems = currentSelectedItems.map(i => i.item)
    setSelectedItems(currentSelectedItems)
  }, [selections])


  //SEARCH

  var timer: NodeJS.Timeout
  const [noResultEmoji, setNoResultEmoji] = useState(randomEmoji('angry'))

  function handleChangeSearch(name: string, value: string) {
    setSearchValue(value)
  }

  function handleConfirmSearch(name: string, value: string) {
    if (timer) clearTimeout(timer)
    if (searchQuery === searchValue) return

    setSearchQuery(searchValue)
  }

  //Automatically triggers the search confirm after a delay while typing.
  useEffect(() => {
    if (enableSearchValue === false) return

    timer = setTimeout(() => {
      handleConfirmSearch('', searchValue)
    }, config.automation.autosearch.delay)
    return () => clearTimeout(timer)
  }, [searchValue])



  //DATES

  function handleDateChange(name: string, value: Date) {
    if (name === 'dateFilterFrom') setFromDateValue(value)
    if (name === 'dateFilterTo') setToDateValue(value)
  }



  //FILTERS

  function handleChangeFilter(key: string, value: any) {
    if (filtersValue == null) return

    let temp = _.clone(filtersValue)
    if (value === null) delete temp[key]
    else temp[key] = value

    setFiltersValue(temp)
  }

  useEffect(() => {
    let amount = 0
    if (filtersValue != null) amount = Object.keys(filtersValue).length
    setActiveFiltersCount(amount)
  }, [filtersValue])

  const openFiltersDialog = () => {
    setFiltersDialogShowed(true)
  }

  const closeFiltersDialog = () => {
    setFiltersDialogShowed(false)
  }



  //DATA

  useEffect(() => {
    if (props.items == null) return

    let data: ListDataSource = []
    props.items.forEach(i => data.push(props.dataConstructor(i)))
    setDataValue(data)

    //Calculates the data length, only if the list is not handled by API requests.
    if (asyncValue === false) setCountValue(data.length ?? 0)

    //Updates the no results emoji if there are no items.
    if (props.items.length === 0) setNoResultEmoji(randomEmoji('angry'))
  }, [props.items])

  useEffect(() => {
    //This funciton is called everytime a change is applied to the list.
    //It automatically refresh the items and updates the session status to persist data between screens.
    fetchItems()
    updateSession()
  }, [pageValue, rowsPerPageValue, sortingValue, filtersValue, searchQuery, fromDateValue, toDateValue])

  function prepareDataForDisplay(): ListDataSource {
    if (dataValue == null || dataValue.length === 0) return []
    if (pageValue == null) return []
    if (rowsPerPageValue == null) return []

    //If the list is handled by API requests just return the data provided.
    if (asyncValue === true) return dataValue

    //Sorts the list based on sorting options, only if the list is not handled by API requests, since in that case the order is automatically handled server-side ans should not be processed client-side.
    let newData: ListDataSource = dataValue
    newData = _.sortBy(dataValue, function (row) {
      const elem = row.data.filter(f => f.key === sortingValue.order)
      if (elem.length >= 1) return elem[0].value
      return undefined
    })
    if (sortingValue.direction === 'desc') newData = _.reverse(newData)

    //Filters the list based on pagination options.
    const startIndex = 0 + (pageValue * rowsPerPageValue)
    const endIndex = startIndex + rowsPerPageValue

    return newData.slice(startIndex, endIndex)
  }

  function formatItemValue(item: ListRowItem) {
    if (searchQuery === '') return item.value
    const isSearchable = props.headers.find(h => h.searchable === true && h.key === item.key)?.searchable === true

    if (isSearchable === true) {
      const reactStringReplace = require('react-string-replace')

      if (typeof item.value === 'string') {
        return reactStringReplace(item.value, searchQuery, (match: any, i: number) => (
          <span className={classes.highlightText}>{match}</span>
        ))
      }
      else {
        // console.log(item.value)
      }
    }

    return item.value
  }



  //FORWARDING

  function performRowClick(row: ListRowProps) {
    if (row.action != null) {
      row.action(row.item)
    }
    if (row.url != null) {
      if (row.url?.external === false) router.history.push(row.url.path)
      if (row.url?.external === true) window.open(row.url.path)
    }

    //Internal link.
    // {...((row.url?.path && row.url?.external === false) && {
    //   component: RouterLink, to: row.url.path
    // })}
    //External link.
    // {...((row.url?.path && row.url?.external === true) && {
    //   component: Link, to: row.url.path, target: '_blank'
    // })}
  }



  //OPTIONS

  const [anchorOptionEl, setAnchorOptionEl] = useState<null | HTMLElement>(null)
  const optionsAreOpen = Boolean(anchorOptionEl)

  const openOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorOptionEl(event.currentTarget)
  }

  const closeOptions = () => {
    setAnchorOptionEl(null)
  }



  //MISC

  function shouldShowHeader(item?: HeaderProps) {
    return item?.show ?? true
  }
  function shouldShowRow(item: ListRowItem) {
    const header = props.headers.find(h => h.key === item.key)
    return shouldShowHeader(header)
  }

  function listHeaderSpecificClass(item: HeaderProps): string {
    let temp = ''

    let typeClass = ''
    if (item.className != null) typeClass = item.className
    else if (item.key === 'id') typeClass = classes.colId
    else if (item.key === 'pageStatus') typeClass = classes.colPageStatus
    else if (item.key === 'pageMeta') typeClass = classes.colPageMeta
    temp = typeClass

    temp = item.flexible ? clsx(temp, classes.colFlexible) : temp

    temp = item.alignedRight ? clsx(temp, classes.colAlignedRight) : clsx(temp, classes.colAlignedLeft)
    if (item.key === 'pageStatus' || item.key === 'pageMeta') temp = clsx(temp, classes.colAlignedCenter)

    return temp
  }

  function listRowSpecificClass(item: ListRowItem): string {
    let temp = ''
    const header = props.headers.find(h => h.key === item.key)

    let typeClass = ''
    if (item.className != null) typeClass = item.className
    else if (item.key === 'id') typeClass = classes.colId_body
    else if (item.key === 'pageStatus') typeClass = classes.colPageStatus_body
    else if (item.key === 'pageMeta') typeClass = classes.colPageMeta_body
    temp = typeClass

    const isBolder = header?.bolder === true
    temp = isBolder ? clsx(temp, classes.colBold) : temp

    const isFlexible = header?.flexible === true
    temp = isFlexible ? clsx(temp, classes.colFlexible) : temp

    const isAlignedRight = header?.alignedRight === true
    temp = isAlignedRight ? clsx(temp, classes.colAlignedRight) : clsx(temp, classes.colAlignedLeft)
    if (item.key === 'pageStatus' || item.key === 'pageMeta') temp = clsx(temp, classes.colAlignedCenter)

    const isCompact = props.compact === true
    if (isCompact === true) temp = clsx(temp, classes.tableRowCompact)

    return temp
  }



  //RENDER

  function renderActions(): React.ReactNode {
    let selectionContent: React.ReactNode = <></>

    //Returns only the selections options.
    if (selections.length > 0) {
      selectionContent = <div className={classes.actionSelectionContainer}>
        <Typography className={classes.actionSelectionText} variant={'body1'}>
          {t('component.CardListing.content.selected-items', {
            tags: { COUNT: selections.length }
          })}
        </Typography>
        {
          (props.selectionOptions != null && props.selectionOptions.length > 0) &&
          <>
            <IconButton className={classes.filterButton} onClick={openOptions}><MoreHorizIcon className={classes.filterButtonIcon} /></IconButton>
            <Menu anchorEl={anchorOptionEl} open={optionsAreOpen} onClick={closeOptions} onClose={closeOptions}>
              {props.selectionOptions.map((option, i) => (
                <MenuItem key={'listAction-' + i} onClick={e => option.action(selectedItems)}>{option.menuItem}</MenuItem>
              ))}
            </Menu>
          </>
        }
      </div>
      return selectionContent
    }

    //Returns everything else.
    let datesContent: React.ReactNode = <></>
    if (enableDatesValue === true) {
      datesContent = <div className={classes.dateFieldsContainer}>
        <CardField type={'date'} size={'small'} name={'dateFilterFrom'} className={classes.dateField}
          value={fromDateValue} onUpdate={(name, value) => handleDateChange(name, value)}
          labelFunc={(d, i) => (d ? t('common.from') + ' ' + moment(d).format(config.app.defaultDateFormat) : t('common.from') + '...')}
        />
        <CardField type={'date'} size={'small'} name={'dateFilterTo'} className={classes.dateField}
          value={toDateValue} onUpdate={(name, value) => handleDateChange(name, value)}
          labelFunc={(d, i) => (d ? t('common.to') + ' ' + moment(d).format(config.app.defaultDateFormat) : t('common.to') + '...')}
        />
      </div>
    }

    let searchContent: React.ReactNode = <></>
    if (enableSearchValue === true) {
      searchContent = <CardField size={'small'} type={'text'} name={'searchBar'}
        className={classes.searchBar}
        value={searchValue} placeholder={t('component.CardListing.header.find')}
        enableEnterConfirm={true}
        startIcon={<SearchIcon />}
        onUpdate={handleChangeSearch} onConfirm={handleConfirmSearch}
      />
    }

    let filtersContent: React.ReactNode = <></>
    if (props.filtersNodes != null) {
      filtersContent = <>
        <Badge overlap="circle" classes={{ badge: classes.filtersBadge }} badgeContent={activeFiltersCount} invisible={activeFiltersCount === 0}>
          <IconButton className={classes.filterButton} onClick={openFiltersDialog}><FilterListIcon className={classes.filterButtonIcon} /></IconButton>
        </Badge>
      </>
    }

    let optionsContent: React.ReactNode = <></>
    if (props.enableExport === true) {
      optionsContent = <>
        {props.isExporting === true && <IconButton className={classes.filterButton} onClick={openOptions}><Spinner type={'small'} /></IconButton>}

        {props.isExporting === false && <>
          {
            (props.exportItems?.length !== 0 || props.exportAllCallback != null) &&
            <>
              <IconButton className={classes.filterButton} onClick={openOptions}><MoreHorizIcon className={classes.filterButtonIcon} /></IconButton>
              <Menu anchorEl={anchorOptionEl} open={optionsAreOpen} onClick={closeOptions} onClose={closeOptions}>
                {props.exportItems?.map(e => e)}
                {props.exportAllCallback != null && <MenuItem onClick={props.exportAllCallback}><ListItemIcon><DownloadIcon /></ListItemIcon>{t('component.CardListing.header.options.export-all')}</MenuItem>}
              </Menu>
            </>
          }
        </>
        }
      </>
    }

    return <><>{datesContent}</><>{searchContent}</><>{filtersContent}</><>{props.actions}</><>{optionsContent}</></>
  }

  return (
    <>
      <Card className={props.fullSize === true ? clsx(classes.card, classes.cardFullSize) : clsx(classes.card)}>
        <CardHeader title={props.title} action={renderActions()} />

        <CardContent isLoading={props.isLoading}>
          <div className={classes.tableContainer}>
            {props.items?.length === 0 && (
              <EmojiResults emojiStyle={'angry'} emoji={noResultEmoji} />
            )}

            {props.items != null && props.items.length > 0 && (
              <div className={classes.table}>
                <div className={classes.tableHead}>
                  <div className={clsx(classes.tableRow, classes.tableHeadRow)}>
                    {/* <TableCell key={-1} className={classes.itemIndex}></TableCell> */}

                    {enableSelectionValue === true &&
                      <div key={'columnSelection-head'} className={clsx(classes.tableCell, classes.tableHeadCell, classes.colCheckbox)}>
                        <Checkbox checked={selections.length === props.items.length} onChange={(e) => { e.stopPropagation(); toggleSelectAll() }} />
                      </div>
                    }

                    {props.headers.map((item, index) => (
                      <Fragment key={'listHeaderItem-' + index + '_' + index} >
                        {shouldShowHeader(item) === true && (
                          <div style={{ fontWeight: FontWeight.Medium }} className={clsx(classes.tableCell, classes.tableHeadCell, listHeaderSpecificClass(item))}>
                            {
                              item.sortable === true ? (
                                <TableSortLabel className={classes.sortingIcon} active={sortingValue?.order === item.key} direction={sortingValue?.direction !== 'asc' ? 'desc' : 'asc'} onClick={e => handleChangeSorting(item.key)} IconComponent={SortingArrowIcon}>
                                  <div className={classes.colItemContainer}>
                                    {item.name ?? t('common.list.column.' + item.key)}
                                    {item.info != null && <Tooltip title={item.info} placement="top"><InfoIcon className={clsx(classes.infoIcon)} /></Tooltip>}
                                  </div>
                                </TableSortLabel>
                              ) : <div className={classes.colItemContainer}>
                                {item.name ?? t('common.list.column.' + item.key)}
                                {item.info != null && <Tooltip title={item.info} placement="top"><InfoIcon className={clsx(classes.infoIcon)} /></Tooltip>}
                              </div>
                            }
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </div>

                <div className={classes.tableBody}>
                  {prepareDataForDisplay().map((row, index) => (
                    <div className={clsx(classes.tableRow, classes.tableBodyRow, row.className, props.compact === true && classes.tableRowCompact)} key={'listBodyItem-' + index}>
                      {enableSelectionValue === true &&
                        <div key={'columnSelection-body_' + index} className={clsx(classes.tableCell, classes.tableBodyCell, classes.colCheckbox)}>
                          <Checkbox checked={rowIsSelected(index)} onChange={(e) => { e.stopPropagation(); handleSelection(index) }} />
                        </div>
                      }
                      <div className={clsx(classes.tableRow, classes.tableBodyRow, row.className, classes.tableBodyRowContent, props.compact === true && classes.tableRowCompact)} onClick={() => performRowClick(row)}>
                        {row.data.map((item, i) => (
                          <Fragment key={'listRow' + index + '_' + i} >
                            {shouldShowRow(item) === true && (
                              <div className={clsx(row.className, classes.tableCell, classes.tableBodyCell, listRowSpecificClass(item))}>
                                <span>{formatItemValue(item)}</span>
                              </div>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

          </div>
        </CardContent>
        {props.enableFooter !== false &&
          <div className={classes.footer}>
            <CardListingFooter
              count={countValue} resultsOptions={props.resultsOptions}
              page={pageValue} handleChangePageCallback={handleChangePage}
              rowsPerPage={rowsPerPageValue} handleChangeRowsPerPageCallback={handleChangeRowsPerPage}
            />
            {enableRefreshValue === true &&
              <Tooltip title={t('component.CardListing.Footer.action.refresh')} placement="top"><IconButton className={classes.refreshButton} onClick={fetchItems}><RefreshIcon className={classes.refreshButtonIcon} /></IconButton></Tooltip>
            }
          </div>
        }
      </Card >

      <CardListingFilterDialog open={filtersDialogShowed} onClose={closeFiltersDialog} filters={props.filtersNodes} onFilterCallback={handleChangeFilter} />
    </>
  )
}

export default CardListing



//UTILS

type StatusIconProps = {
  type: StatusIconType
}
type StatusIconType = 'public' | 'private'

const StatusIcon = ({ ...props }: StatusIconProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  if (props.type === 'public') return <Tooltip title={t('component.CardListing.content.item.status.public')} placement="top"><PublicIcon className={clsx(classes.statusIcon, classes.statusIconPublished)} /></Tooltip>
  if (props.type === 'private') return <Tooltip title={t('component.CardListing.content.item.status.private')} placement="top"><LockIcon className={clsx(classes.statusIcon, classes.statusIconPrivate)} /></Tooltip>
  return <></>
}

export function listStatusValue(type: StatusIconType): React.ReactNode {
  return <StatusIcon type={type} />
}


type MetaStatusIconProps = {
  type: SEOScoreDevice
  score: SEOScoreType
}
const MetaStatusIcon = ({ ...props }: MetaStatusIconProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  let className = classes.metaStatusIconBad
  if (props.score === 'bad') className = classes.metaStatusIconBad
  else if (props.score === 'mediocre') className = classes.metaStatusIconMediocre
  else if (props.score === 'good') className = classes.metaStatusIconGood

  let icon = <></>
  if (props.type === 'desktop') icon = <DesktopMacIcon className={clsx(classes.metaStatusIcon, className)} />
  if (props.type === 'mobile') icon = <PhoneIphoneIcon className={clsx(classes.metaStatusIcon, className)} />
  if (props.type === 'both') {
    if (props.score === 'good') icon = <ThumbUpIcon className={clsx(classes.metaStatusIcon, className)} />
    else if (props.score === 'mediocre') icon = <WarningIcon className={clsx(classes.metaStatusIcon, className)} />
    else icon = <ErrorIcon className={clsx(classes.metaStatusIcon, className)} />
  }
  return <Tooltip title={t('component.CardListing.content.item.metatag-status.' + props.score)} placement="top">{icon}</Tooltip>
}

export function listMetaStatusValue(score: SEOScoreType, type: SEOScoreDevice): React.ReactNode {
  return <MetaStatusIcon type={type} score={score} />
}



type AvailabilityIconProps = {
  type: AvailabilityIconType
}
type AvailabilityIconType = 'available' | 'unavailable'

const AvailabilityIcon = ({ ...props }: AvailabilityIconProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  if (props.type === 'available') return <Tooltip title={t('component.CardListing.content.item.available.available')} placement="top"><CheckIcon className={clsx(classes.availabilityIcon, classes.availabilityIconAvailable)} /></Tooltip>
  if (props.type === 'unavailable') return <Tooltip title={t('component.CardListing.content.item.available.unavailable')} placement="top"><ClearIcon className={clsx(classes.availabilityIcon, classes.availabilityIconUnavailable)} /></Tooltip>
  return <></>
}

export function listAvailabilityValue(type: AvailabilityIconType): React.ReactNode {
  return <AvailabilityIcon type={type} />
}