import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'

enum APIEndpoint {
  GetAllArtists = 'api/artists',
  GetArtistById = 'api/artists/getById',
  CreateArtist = 'api/artists/create',
  UpdateArtist = 'api/artists/update',
  DeleteArtist = 'api/artists/delete',
  GetArtistTranslation = 'api/artists/getTranslation',
  UpdateArtistTranslation = 'api/artists/updateTranslation',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export interface GetArtistsRequestParams extends APIPaginatable, APISortable, APISearchable {
  languageId: number
}

export interface GetArtistsResponse extends APIPaginatableResponse {
  artists: {
    id: number
    keyword: string
    published: boolean | null
    pageId: number
    parentId: number | null
    mainImage: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    }
    year: number | null
    instagram?: string | null
    translation: {
      name: string
      shortDescription: string
    }[],
  }[]
}

enum GetArtistsResponseError {
}

export async function getArtists(params: GetArtistsRequestParams, output?: RequestOutput<GetArtistsResponse, GetArtistsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllArtists,
    params: params,
    output: output,
  })
}




export type GetArtistRequestParams = {
  id: number
}
export type GetArtistResponse = {
  artist: {
    id: number
    keyword: string
    published: boolean | null
    pageId: number
    parentId: number | null
    mainImage: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    }
    year: number | null
    instagram?: string | null
  }
}

enum GetArtistResponseError {
}

export async function getArtist(params: GetArtistRequestParams, output?: RequestOutput<GetArtistResponse, GetArtistResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetArtistById,
    params: params,
    output: output,
  })
}



export type CreateArtistRequestParams = {
  parentId: number | null
}

type CreateArtistResponse = {
  artist: {
    id: number
    name: string
  }
}

enum CreateArtistReponseError {
}

export async function createArtist(params: CreateArtistRequestParams, output?: RequestOutput<CreateArtistResponse, CreateArtistReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateArtist,
    params: params,
    output: output,
  })
}



export type UpdateArtistRequestParams = {
  id: number
  keyword?: string
  parentId?: number | null
  year?: number | null
  instagram?: string | null
}

type UpdateArtistResponse = {
}

enum UpdateArtistReponseError {
}

export async function updateArtist(params: UpdateArtistRequestParams, output?: RequestOutput<UpdateArtistResponse, UpdateArtistReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateArtist,
    params: params,
    output: output,
  })
}



export type DeleteArtistRequestParams = {
  id: number
}

type DeleteArtistResponse = {
}

enum DeleteArtistReponseError {
}

export async function deleteArtist(params: DeleteArtistRequestParams, output?: RequestOutput<DeleteArtistResponse, DeleteArtistReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteArtist,
    params: params,
    output: output,
  })
}




export type GetArtistTranslationRequestParams = {
  artistId: number
  languageId: number
}

export type GetArtistTranslationResponse = {
  artistTranslation: {
    id: number,
    name: string,
    slug: string,
    shortDescription: string | null,
    description: string | null,
    quote: string | null,
    shows: string | null,
    languageId: number
  }
}

enum GetArtistTranslationResponseError {
}

export async function getArtistTranslation(params: GetArtistTranslationRequestParams, output?: RequestOutput<GetArtistTranslationResponse, GetArtistTranslationResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetArtistTranslation,
    params: params,
    output: output,
  })
}



export type UpdateArtistTranslationRequestParams = {
  id: number,
  name?: string | null,
  slug?: string | null,
  shortDescription?: string | null,
  description?: string | null,
  quote?: string | null,
  shows?: string | null,
}

type UpdateArtistTranslationResponse = {
}

enum UpdateArtistTranslationReponseError {
}

export async function updateArtistTranslation(params: UpdateArtistTranslationRequestParams, output?: RequestOutput<UpdateArtistTranslationResponse, UpdateArtistTranslationReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateArtistTranslation,
    params: params,
    output: output,
  })
}
