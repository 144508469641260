import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllExchangeBooksTypes = 'api/bookTypes',
}


export type APIExchangeBookType = {
  id: number
  name?: string
  keyword?: string
}



export type GetExchangeBookTypesResponse = {
  bookTypes: APIExchangeBookType[]
}

enum GetExchangeBookTypesResponseError {
}

export async function getAllExchangeBookTypes(output?: RequestOutput<GetExchangeBookTypesResponse, GetExchangeBookTypesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllExchangeBooksTypes,
    output: output,
  })
}
