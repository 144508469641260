import { Grid } from '@material-ui/core';
import { Language, Page } from 'app/entities/types';
import { MediaLibraryFolderValue, UserPermissionValue } from 'app/values';
import { PageEntityDetailsRefreshableProps } from 'components';
import { PageData, PageStatus, PageCoverImage, PageTags } from './components';
import { RootState } from 'app/session/store'
import { useSelector } from 'react-redux';
import { userCan } from 'app/entities/methods'

interface PageEntityTabDataProps extends PageEntityDetailsRefreshableProps {
  object: Page
  language: Language
  pageId: number
  parents?: Page[] | null
  mediaLibraryFolder?: MediaLibraryFolderValue
  enableTags?: boolean
}
const PageEntityTabData = ({ ...props }: PageEntityTabDataProps) => {
  const session = useSelector((state: RootState) => state.session)

  let enableTags = props.enableTags
  if (props.enableTags == null && userCan(session.user, UserPermissionValue.DeveloperFeatures) === true) enableTags = true

  return (
    <Grid container>
      <Grid item xl={3} md={6} xs={12}>
        <PageData object={props.object} pageId={props.pageId} language={props.language} refreshObject={props.refreshObject} parents={props.parents} />
      </Grid>
      <Grid item xl={3} md={6} xs={12}>
        <PageStatus object={props.object} pageId={props.pageId} refreshObject={props.refreshObject} />
      </Grid>
      <Grid item xl={3} md={6} xs={12}>
        <PageCoverImage object={props.object} pageId={props.pageId} refreshObject={props.refreshObject} mediaLibraryFolder={props.mediaLibraryFolder} />
      </Grid>
      {enableTags === true &&
        <Grid item xl={3} md={6} xs={12}>
          <PageTags object={props.object} pageId={props.pageId} refreshObject={props.refreshObject} />
        </Grid>
      }
    </Grid>
  )
}

export default PageEntityTabData
