
//Defines all the Order Status available in the app.
//IMPORTANT: Must be mapped to each specific order status keyword to work properly.
export enum OrderStatusValue {
  Cart = 'cart',
  Enquiry = 'enquiry',
  PendingPayment = 'pending-payment',
  Processing = 'processing',
  OnHold = 'on-hold',
  Shipped = 'shipped',
  Delivered = 'delivered',
  Canceled = 'canceled',
  Refunded = 'refunded',
  Failed = 'failed',
}
