import executeRequest, { RequestOutput } from 'app/api'


enum APIEndpoint {
  GetCrons = 'api/crons',
  TriggerAbandonedCartCron = 'api/crons/abandonedCart',
  TriggerTransferNFTs = 'api/crons/nftTransfer',
}



export type APICron = {
  code?: string | null
  name?: string | null
  description?: string | null
  frequency?: string | null
}



export type GetCronsResponse = {
  crons: APICron[]
}
enum GetCronsResponseError {
}
export async function getCrons(output?: RequestOutput<GetCronsResponse, GetCronsResponseError>) {
  executeRequest({ endpoint: APIEndpoint.GetCrons, output: output, })
}

export type TriggerAbandonedCartCronResponse = {
  crons: APICron[]
}
enum TriggerAbandonedCartCronResponseError {
}
export async function triggerAbandonedCartCron(output?: RequestOutput<TriggerAbandonedCartCronResponse, TriggerAbandonedCartCronResponseError>) {
  executeRequest({ endpoint: APIEndpoint.TriggerAbandonedCartCron, output: output, })
}

export type TriggerTransferNFTsCronResponse = {
  crons: APICron[]
}
enum TriggerTransferNFTsCronResponseError {
}
export async function triggerTransferNFTsCron(output?: RequestOutput<TriggerTransferNFTsCronResponse, TriggerTransferNFTsCronResponseError>) {
  executeRequest({ endpoint: APIEndpoint.TriggerTransferNFTs, output: output, })
}