
//Defines all the DEFAULT User roles available in the app.
//New roles can always be added from the backoffice without them being mapped here.
//IMPORTANT: Must be mapped to each specific user role keyword to work properly.

export enum ExchangeBookStatusValue {
  Confirmed = 'confirmed',
  Transfered = 'transfered',
  Expired = 'expired',
  Revoked = 'revoked',
}