import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'

enum APIEndpoint {
  GetAllPages = 'api/pages',
  GetPage = 'api/pages/getById',
  CreatePage = 'api/pages/create',
  UpdatePage = 'api/pages/update',
  DeletePage = 'api/pages/delete',
  UpdatePageStatus = 'api/pages/updatePublished',
  UpdatePageImage = 'api/pages/updateMainImage',
  GetPageTranslation = 'api/pages/getTranslation',
  UpdatePageTranslation = 'api/pages/updateTranslation',
  AddTaxonomyToPage = 'api/pages/addTaxonomy',
  RemoveTaxonomyFromPage = 'api/pages/removeTaxonomy',
  GetPageTags = 'api/pages/getTags',
  AddTagToPage = 'api/pages/addTag',
  RemoveTagFromPage = 'api/pages/removeTag',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export interface GetAllPagesRequestParams extends APIPaginatable, APISortable, APISearchable {
  languageId: number
  pageTypeKeyword?: string
  parentId?: number | null
}

export interface GetAllPagesResponse extends APIPaginatableResponse {
  pages: {
    id: number
    keyword: string
    published: boolean | null
    parent: {
      id: number
      translation: {
        name: string
      }
    } | null
    pageTypeId: number | null
    translation: {
      name: string
    }[]
    taxonomies: {
      id: number
      name: string
    }[]
    tags: {
      id: number
      name: string
    }[]
  }[]
}

enum GetAllPagesResponseError {
}

export async function getAllPages(params: GetAllPagesRequestParams, output?: RequestOutput<GetAllPagesResponse, GetAllPagesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllPages,
    params: params,
    output: output,
  })
}


//Retrieve information about a specific common content.

type GetPageRequestParams = {
  id: number
}

export type GetPageResponse = {
  page: {
    id: number
    keyword: string
    published: boolean | null
    parentId: number | null
    pageTypeId: number | null
    mainImage: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    },
    taxonomies: {
      id: number
      name: string
    }[]
    tags: {
      id: number
      name: string
    }[]
  }
}

enum GetPageResponseError {
}

export async function getPage(params: GetPageRequestParams, output?: RequestOutput<GetPageResponse, GetPageResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetPage,
    params: params,
    output: output,
  })
}



//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type CreatePageRequestParams = {
  parentId: number | null
  pageTypeId: number
  tags: number[]
}

type CreatePageResponse = {
  page: {
    id: number
  }
}

enum CreatePageReponseError {
}

export async function createPage(params: CreatePageRequestParams, output?: RequestOutput<CreatePageResponse, CreatePageReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreatePage,
    params: params,
    output: output,
  })
}


//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type UpdatePageRequestParams = {
  id: number
  keyword?: string
  parentId: number | null,
  tags: number[]
}

type UpdatePageResponse = {
}

enum UpdatePageReponseError {
}

export async function updatePage(params: UpdatePageRequestParams, output?: RequestOutput<UpdatePageResponse, UpdatePageReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePage,
    params: params,
    output: output,
  })
}


//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type DeletePageRequestParams = {
  id: number
}

type DeletePageResponse = {
}

enum DeletePageReponseError {
}

export async function deletePage(params: DeletePageRequestParams, output?: RequestOutput<DeletePageResponse, DeletePageReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeletePage,
    params: params,
    output: output,
  })
}



export type UpdatePageStatusRequestParams = {
  id: number
  published: boolean,
}

type UpdatePageStatusResponse = {
}

enum UpdatePageStatusReponseError {
}

export async function updatePageStatus(params: UpdatePageStatusRequestParams, output?: RequestOutput<UpdatePageStatusResponse, UpdatePageStatusReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePageStatus,
    params: params,
    output: output,
  })
}



export type UpdatePageImageRequestParams = {
  id: number
  mediaFileId: number | null,
}

type UpdatePageImageResponse = {
}

enum UpdatePageImageReponseError {
}

export async function updatePageImage(params: UpdatePageImageRequestParams, output?: RequestOutput<UpdatePageImageResponse, UpdatePageImageReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePageImage,
    params: params,
    output: output,
  })
}



//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetPageTranslationRequestParams = {
  pageId: number
  languageId: number
}

export type GetPageTranslationResponse = {
  pageTranslation: {
    id: number,
    name: string,
    slug: string,
    languageId: number
  }
}

enum GetPageTranslationResponseError {
}

export async function getPageTranslation(params: GetPageTranslationRequestParams, output?: RequestOutput<GetPageTranslationResponse, GetPageTranslationResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetPageTranslation,
    params: params,
    output: output,
  })
}



export type UpdatePageTranslationRequestParams = {
  id: number,
  name: string | null,
  slug: string | null,
}

type UpdatePageTranslationResponse = {
}

enum UpdatePageTranslationReponseError {
}

export async function updatePageTranslation(params: UpdatePageTranslationRequestParams, output?: RequestOutput<UpdatePageTranslationResponse, UpdatePageTranslationReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePageTranslation,
    params: params,
    output: output,
  })
}




//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type AddTaxonomyToPageRequestParams = {
  taxonomyId: number
  pageId: number
}

type AddTaxonomyToPageResponse = {
  taxonomy: {
    id: number
    name: string
  }
}

enum AddTaxonomyToPageReponseError {
}

export async function addTaxonomyToPage(params: AddTaxonomyToPageRequestParams, output?: RequestOutput<AddTaxonomyToPageResponse, AddTaxonomyToPageReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.AddTaxonomyToPage,
    params: params,
    output: output,
  })
}


//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type RemoveTaxonomyFromPageRequestParams = {
  taxonomyId: number
  pageId: number
}

type RemoveTaxonomyFromPageResponse = {
}

enum RemoveTaxonomyFromPageReponseError {
}

export async function removeTaxonomyFromPage(params: RemoveTaxonomyFromPageRequestParams, output?: RequestOutput<RemoveTaxonomyFromPageResponse, RemoveTaxonomyFromPageReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.RemoveTaxonomyFromPage,
    params: params,
    output: output,
  })
}



//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type GetPageTagsRequestParams = {
  pageId: number
}
export type GetPageTagsResponse = {
  tags: {
    id: number
    name: string
  }[]
}
enum GetPageTagsReponseError {
}
export async function getPageTags(params: GetPageTagsRequestParams, output?: RequestOutput<GetPageTagsResponse, GetPageTagsReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetPageTags,
    params: params,
    output: output,
  })
}


//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type AddTagToPageRequestParams = {
  tagId: number
  pageId: number
}

type AddTagToPageResponse = {
  tag: {
    id: number
    name: string
  }
}

enum AddTagToPageReponseError {
}

export async function addTagToPage(params: AddTagToPageRequestParams, output?: RequestOutput<AddTagToPageResponse, AddTagToPageReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.AddTagToPage,
    params: params,
    output: output,
  })
}


//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type RemoveTagFromPageRequestParams = {
  tagId: number
  pageId: number
}

type RemoveTagFromPageResponse = {
}

enum RemoveTagFromPageReponseError {
}

export async function removeTagFromPage(params: RemoveTagFromPageRequestParams, output?: RequestOutput<RemoveTagFromPageResponse, RemoveTagFromPageReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.RemoveTagFromPage,
    params: params,
    output: output,
  })
}