import { Card } from '@material-ui/core';
import {
  getPageTags, GetPageTagsRequestParams, GetPageTagsResponse,
  addTagToPage, AddTagToPageRequestParams, getTags, GetTagsResponse,
  removeTagFromPage, RemoveTagFromPageRequestParams
} from 'app/api';
import { PageEntityDetailsRefreshableProps, CardField, CardContent, CardHeader } from 'components';
import { useLocalization } from 'components/methods'
import { Page, Tag } from 'app/entities/types';
import { toast } from 'app/utils'
import { PageTagOptionCategoryValue, tagsForCategory } from 'app/values'
import difference from 'lodash/difference';
import { useEffect, useState } from 'react';
import { default as slugify } from 'slug'
import _ from 'lodash'


interface CardPageTagsProps extends PageEntityDetailsRefreshableProps {
  object: Page,
  pageId: number,
  category?: PageTagOptionCategoryValue
}

enum InputType {
  Tag = 'pageTags',
}

enum UpdateTagAction {
  None = '',
  Add = 'add',
  Remove = 'remove'
}

const Tags = ({ ...props }: CardPageTagsProps) => {
  const { t } = useLocalization()

  const [isLoading, setIsLoading] = useState(true)
  const [tagsList, setTagsList] = useState<Tag[] | null>(null)
  const [activeTags, setActiveTags] = useState<Tag[] | null>(null)


  useEffect(() => {
    fetchTags()
    fetchActiveTags()
  }, [])

  useEffect(() => {
    setActiveTags(props.object.tags ?? [])
  }, [props.object])

  useEffect(() => {
    const isInitialized = tagsList != null
      && activeTags != null
    setIsLoading(!isInitialized)
  }, [tagsList, activeTags])

  const fetchTags = () => {
    const decode = (data: GetTagsResponse): Tag[] => {
      return data.tags
    }

    getTags({
      response(data) {
        const tags = decode(data)
        setTagsList(tags)
      },
      error(error, message) {
        toast.error(message)
      }
    })
  }

  const fetchActiveTags = () => {
    const encode = (): GetPageTagsRequestParams => {
      return {
        pageId: props.pageId
      }
    }
    const decode = (data: GetPageTagsResponse): Tag[] => {
      return data.tags
    }

    getPageTags(encode(), {
      response(data) {
        const tags = decode(data)
        setActiveTags(tags)
      },
      error(error, message) {
        toast.error(message)
      }
    })
  }

  const tagChanged = (tag: Tag, enabled: boolean) => {
    if (activeTags == null) return
    let tags = _.clone(activeTags)

    if (enabled === false) tags = tags.filter(t => t.id !== tag.id)
    else tags?.push(tag)

    handleInputChange(InputType.Tag, tags, null)
  }

  const handleInputChange = (inputType: InputType, value: Tag[], event: any) => {
    if (activeTags == null) return

    const initialCount = activeTags.length
    const nextCount = value.length
    let tagsDiff: Tag[] = []
    let action: UpdateTagAction = UpdateTagAction.None

    if (initialCount > nextCount) {
      action = UpdateTagAction.Remove
      tagsDiff = difference(activeTags, value)
    }
    else if (initialCount < nextCount) {
      action = UpdateTagAction.Add
      tagsDiff = difference(value, activeTags)
    }

    if (tagsDiff.length > 1) return
    const tag = tagsDiff[0]

    switch (inputType) {
      case InputType.Tag: {
        setActiveTags(value)
        updateTags(tag, action)
        break
      }
    }
  }

  function updateTags(tag: Tag, action: UpdateTagAction) {
    if (props.object == null) return

    const addTag = (tag: Tag) => {
      const params: AddTagToPageRequestParams = {
        pageId: props.pageId,
        tagId: tag.id
      }

      addTagToPage(params, {
        response(data) {
          toast.success(t('alert.page-data.tags.update'))
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    const removeTag = (tag: Tag) => {
      const params: RemoveTagFromPageRequestParams = {
        pageId: props.pageId,
        tagId: tag.id
      }

      removeTagFromPage(params, {
        response(data) {
          toast.success(t('alert.page-data.tags.update'))
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    switch (action) {
      case UpdateTagAction.Add:
        addTag(tag)
        break

      case UpdateTagAction.Remove:
        removeTag(tag)
        break

      default:
        break
    }
  }

  function filterTags(): TagSwitchNodeStatus[] {
    if (props.category == null) return []

    let tags: TagSwitchNodeStatus[] = []
    tagsForCategory(props.category).forEach(t => {
      const tag = tagsList?.filter(t2 => t2.name === t)[0]
      const status = activeTags?.filter(t2 => t2.name === t) ?? []
      if (tag != null) tags.push({
        tag: tag,
        enabled: status?.length > 0 ?? false
      })
    })
    return tags
  }

  return (
    <Card>
      <CardHeader title={t('tab.page-data.tags.title')} iconName="tags" isLoading={isLoading} />
      <CardContent isLoading={isLoading}>
        {filterTags().map(t => <TagSwitchNode item={t} handleInputChange={tagChanged} />)}
      </CardContent>
    </Card>
  )
}

export default Tags


type TagSwitchNodeProps = {
  item: TagSwitchNodeStatus,
  handleInputChange: (tag: Tag, enabled: boolean) => void
}
type TagSwitchNodeStatus = {
  tag: Tag,
  enabled: boolean,
}
const TagSwitchNode = ({ ...props }: TagSwitchNodeProps) => {
  const { t } = useLocalization()

  if (props.item.tag == null) return <></>

  return <CardField type={'boolean'} layout={'horizontal'} name={props.item.tag.name}
    label={t('tag.' + slugify(props.item.tag.name) + '.name')} description={t('tag.' + slugify(props.item.tag.name) + '.description')}
    value={props.item.enabled}
    onUpdate={(e, value) => props.handleInputChange(props.item.tag, !props.item.enabled)}
  />
}

export { TagSwitchNode }