
//Defines all the Currencies available in the app.

import { TagValue } from "app/values"

//IMPORTANT: Must be mapped to each specific currency code to work properly.
export enum PageTagOptionCategoryValue {
  Product = 'product',
}

export function tagsForCategory(category: PageTagOptionCategoryValue): TagValue[] {
  switch (category) {
    case PageTagOptionCategoryValue.Product:
      return [
        TagValue.Multiple,
        TagValue.NFT,
        TagValue.Shares,
        TagValue.RenderArtwork,
        TagValue.CoverArtwork,
        TagValue.NotListed,
        TagValue.HiddenPrice,
        TagValue.HiddenPurchase,
        TagValue.ArtMoneyPayment,
        TagValue.ArtExchange,
      ]

    default: return []
  }
}