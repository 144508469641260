import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'
import { APIProduct, APIWallet } from 'app/api'

enum APIEndpoint {
  GetAllProductTokens = 'api/productTokens',
  GetProductTokenById = 'api/productTokens/getById',
  CreateProductToken = 'api/productTokens/batchMint'
}



export type APIProductToken = {
  id: number
  product?: APIProduct
  wallet?: APIWallet
}



export interface GetAllProductTokensRequestParams extends APIPaginatable, APISortable, APISearchable {
  languageId: number
  currencyId: number
  walletId?: number
  productId?: number
}
export interface GetAllProductTokensResponse extends APIPaginatableResponse {
  productTokens: APIProductToken[]
}
enum GetAllProductTokensResponseError {
}
export async function getAllProductTokens(params: GetAllProductTokensRequestParams, output?: RequestOutput<GetAllProductTokensResponse, GetAllProductTokensResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllProductTokens,
    params: params,
    output: output,
  })
}

export interface GetProductTokenRequestParams {
  id: number
}
export interface GetProductTokenResponse {
  productTokens: APIProductToken[]
}
enum GetProductTokenResponseError {
}
export async function getProductToken(params: GetProductTokenRequestParams, output?: RequestOutput<GetProductTokenResponse, GetProductTokenResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductTokenById,
    params: params,
    output: output,
  })
}

export interface CreateProductTokenRequestParams extends APIPaginatable, APISortable, APISearchable {
  languageId?: number
  walletId: number
  productId: number
  quantity: number
  CAFileId: number
  licenseFileId: number
  ownerFileId: number
}
export interface CreateProductTokenResponse extends APIPaginatableResponse {
  productTokens: APIProductToken[]
}
enum CreateProductTokenResponseError {
}
export async function createProductToken(params: CreateProductTokenRequestParams, output?: RequestOutput<CreateProductTokenResponse, CreateProductTokenResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateProductToken,
    params: params,
    output: output,
  })
}