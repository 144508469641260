import { MediaLibraryFile } from 'app/entities/types'

export const getMediaLibraryFileName = (file?: MediaLibraryFile | null): string => {
  if (file == null) return ''
  const name = file.name.substring(0, file.name.lastIndexOf("-"))
  return name
}

export const getMediaLibraryFileFormat = (file?: MediaLibraryFile | null): string => {
  if (file == null) return ''
  const format = file.name.substr(file.name.lastIndexOf('.') + 1)
  return format
}

export const getMediaLibraryFileFullName = (file?: MediaLibraryFile | null): string => {
  if (file == null) return ''
  return file.name + '.' + file.format
}
