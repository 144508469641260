import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'
import { APIUser, APIProduct, APICurrency, APIExchangeBookType, APIExchangeBookStatus } from 'app/api'
import { APIExchangeBook } from './bookRecords'

enum APIEndpoint {
  GetAllExchangeBookMatches = 'api/exchangeBookMatches',
}



export type APIExchangeBookMatch = {
  id: number
  exchangeBookMatchNumber?: string
  exchangeBookBuyer?: APIExchangeBook
  exchangeBookSeller?: APIExchangeBook
  matchStatus?: APIExchangeBookMatchStatus
  creationDate?: Date
}

export type APIExchangeBookMatchStatus = {
  id: number
  name?: string
  keyword?: string
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export interface GetExchangeBookMatchesRequestParams extends APIPaginatable, APISortable, APISearchable {
  userId?: number,
  bookTypeId?: number,
  productId?: number | null
}
export interface GetExchangeBookMatchesResponse extends APIPaginatableResponse {
  exchangeBookMatches: APIExchangeBookMatch[]
}
enum GetExchangeBookMatchesResponseError {
}
export async function getExchangeBookMatches(params: GetExchangeBookMatchesRequestParams, output?: RequestOutput<GetExchangeBookMatchesResponse, GetExchangeBookMatchesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllExchangeBookMatches,
    params: params,
    output: output,
  })
}

