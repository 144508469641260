import { lazy } from 'react'
import { RouteConfig } from 'react-router-config'

const routes: RouteConfig[] = [
	{ path: '/shop/artists', exact: true, component: lazy(() => import('views/screens/project/shop/Artists')), },
	{ path: '/shop/artists/artist/:id/:tab?', exact: true, component: lazy(() => import('views/screens/project/shop/ArtistDetails')) },

	{ path: '/shop/exchange/:tab?', exact: true, component: lazy(() => import('views/screens/project/shop/Exchange')) },
	{ path: '/shop/exchange/book/create', exact: true, component: lazy(() => import('views/screens/project/shop/CreateExchangeBook')) },
]

export default routes