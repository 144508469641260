
//Defines all the DEFAULT User permission available in the app.
//New permission can always be added from the backoffice without them being mapped here.
//IMPORTANT: Must be mapped to each specific user permission keyword to work properly.

export enum UserPermissionValue {
  BackofficeAccess = 'backoffice-access',
  DeveloperFeatures = 'developer-features',
  AdminFeatures = 'admin-features',
  WebsiteContentManagement = 'website-content-management',
  MediaManagement = 'media-management',
  ArticlesManagement = 'articles-management',
  ProductsManagement = 'products-management',
  OrdersManagement = 'orders-management',
  CustomersManagement = 'customers-management',
}