import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { OrderPaymentType } from 'app/entities/types'
import { OrderPaymentTypeValue } from 'app/values'
import CloudOffIcon from '@material-ui/icons/CloudOff'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: '1.1em',
    height: '1.1em',
  },
  iconOffline: {
    color: '#AAAAAA'
  },
  iconCard: {
    color: '#2395FF'
  },
  iconCrypto: {
    color: '#FFCB3D',
  },
  iconBankTransfer: {
    color: '#62D37E',
  }
}))


interface OrderPaymentTypeInfoProps {
  className?: string
  type?: OrderPaymentType | null
}

const OrderPaymentTypeInfo = ({ ...props }: OrderPaymentTypeInfoProps) => {
  const classes = useStyles()

  if (props.type == null) return <>–</>

  return (
    <div className={clsx(classes.root, props.className)}>
      {props.type.keyword === OrderPaymentTypeValue.Offline && <CloudOffIcon className={clsx(classes.icon, classes.iconOffline)} />}
      {props.type.keyword === OrderPaymentTypeValue.Card && <CreditCardIcon className={clsx(classes.icon, classes.iconCard)} />}
      {props.type.keyword === OrderPaymentTypeValue.Crypto && <VpnKeyIcon className={clsx(classes.icon, classes.iconCrypto)} />}
      {props.type.keyword === OrderPaymentTypeValue.BankTransfer && <AccountBalanceWalletIcon className={clsx(classes.icon, classes.iconBankTransfer)} />}
      {props.type.name}
    </div>
  )
}

export default OrderPaymentTypeInfo
