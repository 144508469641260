import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'
import { UserRoleValue } from 'app/values'
import { UserPermission } from 'app/entities/types'

enum APIEndpoint {
  GetCurrentUser = 'api/user',
  GetAllUsers = 'api/users',
  GetUserById = 'api/users/getById',
  CreateUser = 'api/signup',
  UpdateUser = 'api/users/update',
  UpdateUserRole = 'api/users/updateRole',
}



export type APIUser = {
  id: number
  firstname: string
  lastname: string
  username: string
  email: string
  role: {
    id: number
    keyword: string | null
    name: UserRoleValue | string
    permissions: UserPermission[]
  }
  avatar: string
  wallet?: any | null
}



type CurrentUserResponse = {
  user: APIUser
}

enum CurrentUserResponseError {
}

export async function getCurrentUser(output?: RequestOutput<CurrentUserResponse, CurrentUserResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetCurrentUser,
    output: output,
  })
}



export interface GetAllUsersRequestParams extends APIPaginatable, APISortable, APISearchable {
  excludedUserRoleKeyword?: string | null,
  userRoleKeyword?: string | null
}

export interface GetAllUsersResponse extends APIPaginatableResponse {
  users: APIUser[]
}

enum GetAllUsersResponseError {
}

export async function getAllUsers(params: GetAllUsersRequestParams, output?: RequestOutput<GetAllUsersResponse, GetAllUsersResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllUsers,
    params: params,
    output: output,
  })
}



export type GetUserRequestParams = {
  id: number
}
export type GetUserResponse = {
  user: APIUser
}

enum GetUserResponseError {
}

export async function getUser(params: GetUserRequestParams, output?: RequestOutput<GetUserResponse, GetUserResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetUserById,
    params: params,
    output: output,
  })
}



export type CreateUserRequestParams = {
  username: string
  password: string
  firstname: string
  lastname: string
  email: string
}

type CreateUserResponse = {
  user: {
    id: number
  }
}

enum CreateUserResponseError {
}

export async function createUser(params: CreateUserRequestParams, output?: RequestOutput<CreateUserResponse, CreateUserResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateUser,
    params: params,
    output: output,
  })
}



export type UpdateUserRequestParams = {
  id: number
  username: string
  firstname: string
  lastname: string
  email: string
  avatar: string
}

type UpdateUserResponse = {
}

enum UpdateUserResponseError {
}

export async function updateUser(params: UpdateUserRequestParams, output?: RequestOutput<UpdateUserResponse, UpdateUserResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateUser,
    params: params,
    output: output,
  })
}



export type UpdateUserUserRoleRequestParams = {
  id: number
  roleId: number
}

type UpdateUserUserRoleResponse = {
}

enum UpdateUserUserRoleResponseError {
}

export async function updateUserUserRole(params: UpdateUserUserRoleRequestParams, output?: RequestOutput<UpdateUserUserRoleResponse, UpdateUserUserRoleResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateUserRole,
    params: params,
    output: output,
  })
}