import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  image: {
    width: '20px',
    height: '20px',
  },
  imageSmall: {
    width: '16px',
    height: '16px',
  },
  imageMedium: {
    width: '20px',
    height: '20px',
  },
  imageLarge: {
    width: '30px',
    height: '30px',
  },
  round: {
    borderRadius: '10px',
    overflow: 'hidden',
  },
  name: {
    marginLeft: theme.spacing(0.5)
  },
}))


type LangugeFlagProps = {
  className?: string
  languageCode: string
  countryData?: { name: string, code: string }
  name?: string | React.ReactNode
  rest?: any
  round?: boolean
  size?: 'small' | 'medium' | 'large'
}
const LanguageFlag = ({ ...props }: LangugeFlagProps) => {
  const classes = useStyles()
  if (props.languageCode.length === 0) return <></>

  const roundValue = props.round ?? true
  let roundClass = classes.round
  if (roundValue !== true) roundClass = ''

  let sizeClass = classes.imageMedium
  if (props.size === 'small') sizeClass = classes.imageSmall
  if (props.size === 'medium') sizeClass = classes.imageMedium
  if (props.size === 'large') sizeClass = classes.imageLarge

  return (
    <div className={clsx(classes.container, props.className)} {...props}>
      <img className={clsx(classes.image, roundClass, sizeClass)} src={'/images/flags/' + props.languageCode.toLowerCase() + '.svg'} alt={props.languageCode} />
      {props.name != null && <span className={classes.name}>{props.name}</span>}
      {(props.name == null && props.countryData != null) && <span className={classes.name}>{props.countryData.name}</span>}
    </div>
  )
}

export default LanguageFlag
