import React, { useState, useEffect } from 'react'
import { InputBaseProps, Menu, MenuItem, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { LanguageFlag } from 'components';
import { Language } from 'app/entities/types';

const useStyles = makeStyles((theme) => ({
  button: {
    height: '36px'
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  flag: {
    marginLeft: theme.spacing(1),
    display: 'flex'
  },
  menuFlag: {
    marginRight: theme.spacing(1),
    display: 'flex'
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  }
}))

interface LanguageSelectorProps extends InputBaseProps {
  languages: Language[]
  selectedLanguage: Language
  updateLanguageCallback?: (language: Language) => void
}
const LanguageSelector = ({ ...props }: LanguageSelectorProps) => {
  const classes = useStyles()

  const [currentLanguage, setCurrentLanguage] = useState<Language>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    setCurrentLanguage(props.selectedLanguage)
  }, [])

  function handleLanguageChange(value: number) {
    const language = props.languages.filter(l => l.id === value)[0]
    setCurrentLanguage(language)
    if (props.updateLanguageCallback != null) props.updateLanguageCallback(language)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Don't show the Language Selector if there's only one language available.
  if (props.languages.length === 1) return (null)

  return (
    <>
      <Button className={classes.button} variant="text" onClick={handleClick}><span className={classes.text}>{currentLanguage?.name ?? ''}</span><LanguageFlag className={classes.flag} languageCode={currentLanguage?.code ?? ''} /></Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.languages.map((l, i) => (
          <MenuItem className={classes.menuItem} key={i} onClick={e => { handleClose(); handleLanguageChange(l.id) }}><LanguageFlag className={classes.menuFlag} languageCode={l.code} name={l.name} /></MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LanguageSelector
