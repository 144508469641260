import PanoramaIcon from '@material-ui/icons/Panorama'
// import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import FaceIcon from '@material-ui/icons/Face'
import BrushIcon from '@material-ui/icons/Brush'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
// import FavoriteIcon from '@material-ui/icons/Favorite'
import PaymentIcon from '@material-ui/icons/Payment'
import ReceiptIcon from '@material-ui/icons/Receipt'
import TimelineIcon from '@material-ui/icons/Timeline'

const items = [
  {
    title: 'sidebar.title.shop', pages: [
      { title: 'sidebar.item.products', icon: PanoramaIcon, href: '/shop/items/', },
      { title: 'sidebar.item.product-owners', icon: BrushIcon, href: '/shop/artists', },
      // { title: 'sidebar.item.wishlists', icon: FavoriteIcon, href: '/shop/wishlists', },
      { title: 'sidebar.item.carts', icon: ShoppingCartIcon, href: '/shop/carts', },
      { title: 'sidebar.item.orders', icon: PaymentIcon, href: '/shop/orders', },
      { title: 'sidebar.item.exchange', icon: TimelineIcon, href: '/shop/exchange', },
      { title: 'sidebar.item.invoices', icon: ReceiptIcon, href: '/shop/invoices', },
      { title: 'sidebar.item.customers', icon: FaceIcon, href: '/shop/customers', },
    ]
  },
]

export default items