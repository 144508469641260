import clsx from 'clsx'
import { makeStyles, Chip, ChipProps } from '@material-ui/core'
import { Color } from 'theme/style'
import { ExchangeBookTypeValue } from 'app/values'
import { useLocalization } from 'components/methods'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    '& >svg': {
      fontSize: '20px',
    }
  },
  tagBuy: {
    color: Color.Success
  },
  tagSell: {
    color: Color.Error
  },
}))

interface ExchangeBookProps extends ChipProps {
  className?: string
  type?: ExchangeBookTypeValue
}

const ExchangeBookTag = ({ ...props }: ExchangeBookProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  const typeValue = props.type ?? 'default'
  let tagClass = ''
  if (typeValue === 'buy') tagClass = classes.tagBuy
  if (typeValue === 'sell') tagClass = classes.tagSell


  return (
    <div className={classes.root}>
      <div className={clsx(classes.icon, tagClass)}>
        {typeValue === 'buy' && <ArrowDropUpIcon />}
        {typeValue === 'sell' && <ArrowDropDownIcon />}
      </div>
      {t('component.ExchangeBookTag.' + typeValue)}
    </div>
  )
}

export default ExchangeBookTag
