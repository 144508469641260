
//Defines all the Tags available in the app.
//IMPORTANT: Must be mapped to each specific page tag keyword to work properly.
export enum TagValue {
  System = 'System',
  ThankYou = 'Thank You',
  Onboarding = 'Onboarding',
  Policy = 'Policy',
  Shop = 'Shop',

  RenderArtwork = 'Render Artwork',
  CoverArtwork = 'Cover Artwork',
  Multiple = 'Multiple',
  Shares = 'Shares',
  NFT = 'NFT',
  NotListed = 'Not Listed',
  HiddenPrice = 'Hidden Price',
  HiddenPurchase = 'Hidden Purchase',
  ArtMoneyPayment = 'Art Money Price',
  ArtExchange = 'Art Exchange',
}