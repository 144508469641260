import PageIcon from '@material-ui/icons/Description'
import CommonTextIcon from '@material-ui/icons/TextFields'
import ImportContactsIcon from '@material-ui/icons/ImportContacts';

const items = [
  {
    title: 'sidebar.title.website', pages: [
      { title: 'sidebar.item.pages', icon: PageIcon, href: '/website/pages' },
      { title: 'sidebar.item.dictionary', icon: CommonTextIcon, href: '/website/dictionary' },
      { title: 'sidebar.item.blog', icon: ImportContactsIcon, href: '/website/blog/', },
    ]
  },
]

export default items