import executeRequest, { RequestOutput } from 'app/api'


enum APIEndpoint {
  GetConfigs = 'api/configParameters',
  UpdateConfigs = 'api/configParameters/update',
}



export type APIConfig = {
  id: number
  keyword?: string | null
  value?: string | null
}



export type GetConfigsResponse = {
  configParameters: APIConfig[]
}
enum GetConfigsResponseError {
}
export async function getConfigs(output?: RequestOutput<GetConfigsResponse, GetConfigsResponseError>) {
  executeRequest({ endpoint: APIEndpoint.GetConfigs, output: output, })
}


export type UpdateConfigRequestParams = {
  id: number
  keyword: string
  value: string
}
export type UpdateConfigResponse = {
}
enum GetConfigResponseError {
}
export async function updateConfig(params: UpdateConfigRequestParams, output?: RequestOutput<GetConfigsResponse, GetConfigsResponseError>) {
  executeRequest({ params: params, endpoint: APIEndpoint.UpdateConfigs, output: output, })
}