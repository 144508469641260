import UsersIcon from '@material-ui/icons/PeopleAlt'
import SettingsIcon from '@material-ui/icons/Settings'
import AdbIcon from '@material-ui/icons/Adb'
import CodeIcon from '@material-ui/icons/Code'

const items = [
  {
    title: 'sidebar.title.admin', pages: [
      { title: 'sidebar.item.users', icon: UsersIcon, href: '/admin/users' },
      { title: 'sidebar.item.settings', icon: SettingsIcon, href: '/admin/settings' },
      { title: 'sidebar.item.automation', icon: AdbIcon, href: '/admin/automation' },
      { title: 'sidebar.item.dev', icon: CodeIcon, href: '/dev/overview' },
    ]
  },
]
export default items